<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{ zIndex: options.zIndex }"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">요청</v-btn>
      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">공유 요청</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container grid-list-xs class="pb-0 mb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12">
              <!-- <v-text-field
                ref="refMasterEmail"
                v-model="form.masterEmail"
                required
                v-validate="'required|email|cantHangul|cantEmailSpc|max:50'"
                :error-messages="errors.collect('masterEmail')"
                data-vv-name="masterEmail"
                placeholder="master@gmail.com"
                maxlength="50"
                counter="50"
                name="masterEmail"
                type="text"
                label="✶ 관리(대행)자"
                @keydown.enter="agree"
              ></v-text-field> 에게 -->
              <v-text-field
                ref="refMasterEmail"
                v-model="form.masterEmail"
                required
                v-validate="'required|email|cantHangul|cantEmailSpc|max:50'"
                :error-messages="errors.collect('masterEmail')"
                data-vv-name="masterEmail"
                placeholder="master@gmail.com"
                maxlength="50"
                counter="50"
                name="masterEmail"
                type="text"
                label="✶관리(대행)자"
                @keyup="setUpEmailAddress"
              ></v-text-field> 에게
            </v-col>
            <v-col cols="12" xs="12">
              <v-card outlined class="mt-3 mb-3">
                <v-card-text>
                  <div class="subheading mt-1 mb-2">
                    <span class="font-weight-regular mr-3">아이디</span>
                    <span class="font-weight-bold blue--text ml-2">{{ form.email }}</span>
                  </div>
                  <div class="subheading mt-1 mb-2">
                    <span class="font-weight-regular mr-3">이름</span>
                    <span class="font-weight-bold blue--text ml-2">{{ form.name }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12">
              <div class="mt-1 mb-4">
                <p>님의 공유를 요청합니다.</p>
              </div>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                text
                color="deep-orange"
                icon="mdi-alert-outline"
                dense
              >
                공유요청할 관리(대행)자의 이메일 주소를 정확히 입력해 주시기 바랍니다.
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </baseModal>

    <v-dialog
      v-model="loading"
      fullscreen
    >
      <v-container fluid fill-height style="background-color: rgba(115, 115, 115, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
          :size="150"
          :width="10"
          color="primary"
          indeterminate></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'
// filters
import isHan from '@/filters/isHangul'

export default {
  components: {
    baseModal
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    loading: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 650,
      zIndex: 300
    },

    form: {
      masterEmail: '',
      email: '',
      name: ''
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        masterEmail: '관리(대행)자 이메일'
      }
    }
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.initForm() // 폼 초기화

    if (this.$store.state.ui) {
      // 이미 로그인된 상태라 저장소에 유저정보가 저장된 경우.
      this.dialog = true

      // 이메일과 이름은 저장된 토큰에서 가져온다.
      this.form.email = this.$store.state.ui.email
      this.form.name = this.$store.state.ui.name

      // !![2021.10.19] 관리자 메일 입력창에 포커싱
      this.$nextTick(() => {
        // this.$refs.refMasterEmail.focus()
        setTimeout(() => {
          this.$refs.refMasterEmail.focus()
        }, 500)
      })
    } else {
      // 이도저도 아니면 뭔가 잘못되었으니 초기화면으로
      this.redirect('/')
    }
  },

  created () {
    // 중요: vee-validate 커스텀 룰 적용 - 한글은 허용안됨
    this.$validator.extend('cantHangul', {
      getMessage: field => `한글은 허용되지 않습니다`,
      validate: value => {
        return !isHan(value) // false 리턴이면 오류로 처리됨
      }
    })

    // 중요: vee-validate 커스텀 룰 적용 - 특수문자 허용안됨
    // !! 이메일용이라 @-_. 만 허용된다.
    this.$validator.extend('cantEmailSpc', {
      getMessage: field => 'email항목의 값은 유효한 이메일 형식이어야 합니다',
      validate: value => {
        const pattern = /[{}[\]/?,;:|)*~`!^+<>#$%&\\=('"]/gi
        return !pattern.test(value)
      }
    })
  },

  methods: {
    isHan, // 한글 필터
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 폼 초기화
    initForm () {
      // form 초기화
      // this.$refs.form.reset()
      this.form = {
        masterEmail: '',
        email: '',
        name: ''
      }
    },
    async agree () {
      try {
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        this.dialog = false
        this.loading = true

        const { data } = await this.$axios.post('auth/requestLawWorkShare', this.form)
        if (!data.success) {
          // 중요: 백앤드 에러시 > 관리자 이메일이 잘못되었던가 하여 에러가 난 경우 처리
          this.form.masterEmail = '' // 관리자 이메일 비우고
          this.loading = false // 로딩 닫기
          //
        } else {
          this.loading = false

          // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
          const target = 'confirmDialog'
          const pop = await this.findParentRefs(this.$parent, target)
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          if (await pop.open('성공', `관리(대행)자에게 메일을 발송하였습니다.<br>공유설정 완료 메일이 도착하면 다시 로그인하시기 바랍니다.`, { color: 'green', width: 380 })) {
            this.redirect('/')
          } else {
            this.redirect('/')
          }
        }
      } catch (e) {
        // this.initForm() // 폼 초기화
        // this.redirect('/auth/regist2')
        // this.dialog = false

        this.sbpop(e)
      }
    },
    // 구분:[2021.10.20] 이메일의 마지막에 @를 입력하면 gmail.com 을 넣어준다.
    async setUpEmailAddress () {
      const emLen = this.form.masterEmail.length
      if (emLen > 0 && this.form.masterEmail.endsWith('@') && (this.form.masterEmail.indexOf('@') === emLen - 1)) {
        this.form.masterEmail = this.form.masterEmail + 'gmail.com'
      }
    },
    cancel () {
      // 취소시 앞 페이지로 돌아간다
      this.initForm() // 폼 초기화
      this.redirect('/auth/regist2')
      this.dialog = false
    },
    redirect (to = '') {
      this.dialog = false
      this.$router.push(to)
    }
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem
}
</style>
